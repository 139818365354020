import {availablePermissions, isStaff} from "./auth";
import {NextPageContext} from "next";

const isAuthorized = ({permissionToCheck, ctx}:{permissionToCheck?:string, ctx?: NextPageContext}): boolean => {
  // const availablePermissions = get;
  if(isStaff(ctx)){
    if(permissionToCheck){
      const myPermissions = availablePermissions(ctx);
      return  myPermissions.includes(permissionToCheck);
    }else {
      return true
    }

  }else{
    return true;
  }
};
export default isAuthorized;

export const PERMISSIONS = {
  SHOW_CLOUT_PRICE_MARGIN:'show_clout_price_margin',

  CRITICAL_ACCESS_ANALYTICS:'critical_access_analytics',
  CRITICAL_ACCESS_EXPORT_DATA: 'critical_access_export_data',
  CRITICAL_ACCESS_PAYMENTS:'critical_access_payments',

  PUSH_SHOPIFY: 'important_access_push_shopify',
  MANAGE_ORDERS: 'important_access_manage_orders',
  CONFIRM_PREPAID_ORDERS: 'important_access_confirm_prepaid_orders',
  EXPORT_DATA: 'important_access_export_data',
  MANAGE_NDR: 'important_access_manage_ndr',
  EXPORT_DATA_MANAGE_NDR: 'important_access_export_data_manage_ndr',
  MANAGE_PRODUCTS: 'important_access_manage_products',
  SOURCE_PRODUCT: 'important_access_source_product',
};
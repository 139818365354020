import { SidebarNavProp } from "types";

import PAGE_CONFIG from "constants/pageConfig";

const { childNavs: rtoIntelligenceChildNavs, ...rtoIntelligencePageConfig } = PAGE_CONFIG.rtoIntelligence;
const { childNavs: valueAddedServicesChildNavs, ...valueAddedServicesPageConfig } = PAGE_CONFIG.valueAddedServices;

const navData: SidebarNavProp[][] = [
  [
    PAGE_CONFIG.analytics
  ],
  // Divider
  [
    PAGE_CONFIG.home,
    PAGE_CONFIG.manageOrders,
    PAGE_CONFIG.manageNdr,
    PAGE_CONFIG.manageProducts,
    PAGE_CONFIG.sourceProduct,
    {
      ...rtoIntelligencePageConfig,
      childNavs: [
        rtoIntelligenceChildNavs.pincodes,
        rtoIntelligenceChildNavs.faqs,
      ],
    },
  ],
  [
    PAGE_CONFIG.reports,
    PAGE_CONFIG.payments,
    PAGE_CONFIG.gstInvoices,
    {
      ...valueAddedServicesPageConfig,
      childNavs: [
        valueAddedServicesChildNavs.whatsapp,
      ],
    },
  ],
  // Divider
  [
    PAGE_CONFIG.cloutTraining,
    PAGE_CONFIG.cloutUpdates,
    PAGE_CONFIG.support,
    PAGE_CONFIG.buyerDsrRequest,
  ],
];

export const bottomNavData: SidebarNavProp[] = [
  PAGE_CONFIG.home,
  PAGE_CONFIG.discoveryVideoFeed,
  PAGE_CONFIG.analytics,
  PAGE_CONFIG.payments,
  PAGE_CONFIG.accountPopup,
];

export default navData;

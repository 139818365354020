import React from 'react';
import Tooltip from "../Tooltip/_default/Tooltip";
const getPositionAttributes = (position: string) => {
  switch (position){
    case 'top':
      return {
        position:position
      }
    case 'bottom':
      return {
        position:position
      }
    case 'right':
      return {
        position:position
      }
    default:
      return {
        position:position
      }
  }

}
// const get
const TooltipForNoAccess = ({ position = '', hasAccess, children, message, wrapperClass = '', messageWrapperClass = ''  }: { position: string;message: string; hasAccess: boolean; children : React.ReactElement; wrapperClass?: string, messageWrapperClass?: string}) => {
  return (
<>
      {hasAccess ? {...children}: <Tooltip
        {...getPositionAttributes(position)}
        message={message}
        customComponent={{...children}}
        wrapperClass={wrapperClass}
        messageWrapperClass={messageWrapperClass}
      /> }
</>
  );
};

export default TooltipForNoAccess;
